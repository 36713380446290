import { useState } from "react";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";
import { getPlans } from "../../../handlers/productHandler";
import Table from "../../../components/table/Table";
import { Oval } from "react-loader-spinner";
import CreatePlanModal from "./CreatePlanModal";
import UpdatePlanModal from "./UpdatePlansModal";
import { exportData } from "../../../handlers/exportData";
import { toast } from "../../../components/toast/toast";

interface IData {
  name: string;
  description: string;
  email: string;
  is_active: boolean;
  slug: string;
  no_of_plan_types: string;
  image: Array<string>;
}

const Plans = () => {
  const [cookies] = useCookies(["sofresh-admin-token"]);
  // const navigate = useNavigate()

  // CREATE PLAN MODAL
  const [openCreatePlanModal, setOpenCreatePlanModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const handleOpenCreatePlanModal = () => {
    setOpenCreatePlanModal(true);
  };

  const handleCloseCreatePlanModal = () => {
    setOpenCreatePlanModal(false);
  };
  // END OF CREATE PLAN MODAL

  // UPDATE PLAN MODAL
  const [openUpdatePlanModal, setOpenUpdatePlanModal] = useState(false);
  const [slug, setSlug] = useState("");

  const handleOpenUpdatePlanModal = (slug: string) => {
    setSlug(slug);
    setOpenUpdatePlanModal(true);
  };

  const handleCloseUpdatePlanModal = () => {
    setSlug("");
    setOpenUpdatePlanModal(false);
  };
  // END OF UPDATE PLAN MODAL

  // GET PLAN TO POPULATE THE FIELDS
  const { data, isLoading } = useQuery("plans", () =>
    getPlans(cookies["sofresh-admin-token"])
  );
  // console.log('DATA: ', data)

  const handleExportData = async () => {
    try {
      setIsDownloading(true);
      const responseData = await exportData({
        token: cookies["sofresh-admin-token"],
        type: "plan",
      });

      if (responseData.status === "downloaded") {
        toast.success("Data successfully downloaded");
      }
    } catch (error) {
      toast.error("Failed to download! Try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  const columns = [
    {
      name: "Plan Name",
      selector: (row: IData) => row.name,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row: IData) => (
        <img
          src={row.image[0]}
          alt={row.name}
          style={{ width: "40px", aspectRatio: "1/1" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: IData) => `${row.email}`,
      sortable: true,
    },
    {
      name: "No of Plans",
      selector: (row: IData) => `${row.no_of_plan_types}`,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: IData) =>
        row.is_active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">Inactive</span>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row: IData) => (
        <div className="flex align-items-center">
          {/* <button
            style={{
              background: "transparent",
              outline: "transparent",
              border: "transparent",
            }}
          >
            <i className="bi bi-eye-fill me-2"></i>
          </button> */}
          <button
            onClick={() => handleOpenUpdatePlanModal(row.slug)}
            // onClick={() => navigate(`/plans/${row.slug}/edit`)}
            style={{
              background: "transparent",
              outline: "transparent",
              border: "transparent",
            }}
          >
            <i className="bi bi-pencil-square me-2"></i>
          </button>
          {/* <button
            style={{
              background: "transparent",
              outline: "transparent",
              border: "transparent",
            }}>
            <i className="bi bi-trash-fill"></i>
          </button> */}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3">Plans</h1>

            <div className="d-flex gap-3">
              <button
                onClick={handleExportData}
                className="btn btn-sm btn-success"
              >
                {isDownloading ? (
                  <Oval
                    color="white"
                    wrapperClass="mx-4"
                    height={10}
                    width={10}
                  />
                ) : (
                  "Export Data"
                )}
              </button>

              <button
                className="btn btn-sm btn-warning text-light"
                onClick={handleOpenCreatePlanModal}
              >
                Create Plan <i className="bi bi-bag-plus-fill"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isLoading ? (
                  <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                    <Oval
                      height="40"
                      width="40"
                      color="#495057"
                      visible={true}
                    />
                  </div>
                ) : (
                  <Table data={data} columns={columns} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {openCreatePlanModal && (
        <CreatePlanModal
          isOpen={openCreatePlanModal}
          onClose={handleCloseCreatePlanModal}
        />
      )}

      {openUpdatePlanModal && (
        <UpdatePlanModal
          isOpen={openUpdatePlanModal}
          slug={slug}
          onClose={handleCloseUpdatePlanModal}
        />
      )}
    </main>
  );
};

export default Plans;
