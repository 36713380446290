import {
  // ChangeEvent,
  useContext,
  useState,
  // useState
} from "react";
import { AuthContext } from "../../../context/AppContext";
import {
  // useQueryClient,
  //  useMutation,
  useQuery,
} from "react-query";
import { useCookies } from "react-cookie";
import Table from "../../../components/table/Table";
import { Oval } from "react-loader-spinner";
import {
  getAdminOrders,
  // updateOrderRelatedStore,
} from "../../../handlers/orderHandler";
import { formatToNigerianNaira } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { exportData } from "../../../handlers/exportData";
import { toast } from "../../../components/toast/toast";
// import Modal from "../../../components/Modal";
// import { getStores } from "../../../handlers/storeHandler";
// import Button from "../../../components/button/button";
// import styles from "./Orders.module.css";
// import { toast } from "../../../components/toast/toast";

// interface Store {
//   id: string;
//   store_name: string;
// }
// interface HandleOpenModalProps {
//   id: string;
//   name: string;
//   reference: string;
// }

const UserOrders = () => {
  // const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  // const [openModal, setOpenModal] = useState(false);
  // const [storeID, setStoreID] = useState<string>("");
  // const [storeName, setStoreName] = useState<string>("");
  // const [orderReference, setOrderReference] = useState<string>("");

  const { data, isLoading } = useQuery(
    [`${cookies["sofresh-admin-user"]?.role}-orders`],
    () =>
      getAdminOrders({
        token: cookies["sofresh-admin-token"],
        role: cookies["sofresh-admin-user"]?.role,
      })
  );
  // console.log('ADMIN_ORDERS: ', data)
  // console.log('ADMIN_ORDERS_STORE: ', data && data[0]?.store?.store_name)

  const handleExportData = async () => {
    try {
      setIsDownloading(true);
      const responseData = await exportData({
        token: cookies["sofresh-admin-token"],
        type: "order",
      });

      if (responseData.status === "downloaded") {
        toast.success("Data successfully downloaded");
      }
    } catch (error) {
      toast.error("Failed to download! Try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  // TABLE DATA  //
  const columns = [
    {
      name: `Customer's Name`,
      selector: (data: IDashboardData) => data.user.name,
      sortable: true,
    },
    {
      name: `Customer's Email`,
      selector: (data: IDashboardData) => data.user.email,
      sortable: true,
    },
    {
      name: `Pickup/Delivery`,
      selector: (data: IDashboardData) => data.delivery_type,
      sortable: true,
    },
    {
      name: `Delivery Fee`,
      selector: (data: IDashboardData) =>
        data.delivery_fee
          ? formatToNigerianNaira(data.delivery_fee)
          : formatToNigerianNaira(0),
      sortable: true,
    },
    // {
    //   name: 'Store',
    //   selector: (data: IDashboardData) => data.store.store_name,
    //   sortable: true,
    // },
    {
      name: "Price",
      selector: (data: IDashboardData) =>
        formatToNigerianNaira(data.total_amount),
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (data: IDashboardData) =>
        data.order_status === "completed" ? (
          <span className="badge bg-success">Completed</span>
        ) : (
          <span className="badge bg-warning">Pending</span>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      omit: user?.role === "subadmin",
      selector: (data: IDashboardData) => (
        <div className="flex align-items-center">
          <button
            onClick={() => navigate(`/orders/${data.reference}`)}
            style={{
              background: "transparent",
              outline: "transparent",
              border: "transparent",
            }}
          >
            <i className="bi bi-eye-fill me-2"></i>
          </button>
          {/* {user.role === 'admin' && ( */}
          {/* <button
            onClick={() =>
              handleOpenModal({
                id: data.store.id,
                name: data.store.store_name,
                reference: data.reference,
              })
            }
            style={{
              background: "transparent",
              outline: "transparent",
              border: "transparent",
            }}>
            <i className="bi bi-pencil-square me-2"></i>
          </button> */}
          {/* )} */}
          {/* <button
            style={{
              background: 'transparent',
              outline: 'transparent',
              border: 'transparent',
            }}>
            <i className='bi bi-trash-fill'></i>
          </button> */}
        </div>
      ),
      sortable: true,
    },
  ];

  // GET STORE DATA
  // const { data: storesData, isLoading: isStoresDataLoading } = useQuery(
  //   "stores",
  //   () => getStores(cookies["sofresh-admin-token"])
  // );
  // console.log('STORE DATA: ', typeof data)
  // console.log('STORE DATA: ', storesData)

  // const handleOpenModal = ({ id, name, reference }: HandleOpenModalProps) => {
  //   setOpenModal(true);
  //   setStoreID(id);
  //   setStoreName(name);
  //   setOrderReference(reference);
  // };
  // console.info('storeID: ', storeID)
  // console.info('storeName: ', storeName)
  // console.info('orderReference: ', orderReference)

  // const handleCloseModal = () => {
  //   setOpenModal(false);
  //   setStoreID("");
  //   setStoreName("");
  //   setSelectedStore("");
  //   setOrderReference("");
  // };

  // HANDLE FORM FIELDS & SUBMISSION
  // const [selectedStore, setSelectedStore] = useState<string>(storeID);

  // MUTATE FORM DATA BEFORE SUBMISSION
  // const {
  //   mutate: updateOrderRelatedStoreMutation,
  //   isLoading: isUpdateOrderRelatedStoreMutationLoading,
  // } = useMutation(updateOrderRelatedStore, {
  //   onSuccess: async res => {
  //     // console.info({ res })
  //     await queryClient.invalidateQueries([
  //       `${cookies["sofresh-admin-user"]?.role}-orders`,
  //     ]);
  //     handleCloseModal();
  //     toast.success("Store changed successfully");
  //   },
  //   onError: (e: string) => {
  //     console.error({ e });
  //     navigate("/orders");
  //     toast.error(e);
  //   },
  // });

  // const handleStoreChange = (event: ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedStore(event.target.value);
  // };
  // console.log('selectedStore: ', selectedStore)

  // const STORE_ID = selectedStore
  //   ? { store_id: selectedStore }
  //   : { store_id: storeID };

  // HANDLE FORM SUBMISSION
  // const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();

  //   // console.info('STORE_CHANGE_INITIATED')
  //   // console.info('STORE_ID: ', STORE_ID)
  //   // console.info('orderReference: ', orderReference)

  //   updateOrderRelatedStoreMutation({
  //     token: cookies["sofresh-admin-token"],
  //     store_id: STORE_ID,
  //     reference: orderReference,
  //     role: cookies["sofresh-admin-user"]?.role,
  //   });
  // };

  return (
    <main className="content">
      <div className="container-fluid p-0">
        {(user?.role === "admin" || user?.role === "subadmin") && (
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-between">
              <h4 className="h4 py-3">Recent Orders</h4>

              <button
                onClick={handleExportData}
                className="btn btn-sm btn-success h-fit"
              >
                {isDownloading ? (
                  <Oval
                    color="white"
                    wrapperClass="mx-4"
                    height={10}
                    width={10}
                  />
                ) : (
                  "Export Data"
                )}
              </button>
            </div>

            <div className="col-12 d-flex">
              <div className="card flex-fill">
                <div className="card">
                  <div className="card-body">
                    {isLoading ? (
                      <div className="my-8 w-full h-full flex align-items-center justify-center">
                        <Oval
                          height="40"
                          width="40"
                          color="#495057"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <Table data={data} columns={columns} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* {openModal && (
              <Modal
                isOpen={openModal}
                onClose={handleCloseModal}
                title="Edit Order"
                subTitle={`Update the Order's status and store from selecting the dropdown below`}>
                {isStoresDataLoading ? (
                  <div className="d-flex  align-items-center justify-content-center pt-4">
                    <Oval
                      wrapperClass="ml-2"
                      height={50}
                      width={50}
                      strokeWidth={5}
                    />
                  </div>
                ) : (
                  <form
                    className={`${styles.changeStoreModalForm} d-flex flex-column gap-4`}
                    onSubmit={handleFormSubmit}>
                    <div className="form-group d-flex flex-column gap-2 py-2">
                      <label
                        className={styles.changeStoreModalFormTitle}
                        htmlFor="stores">
                        Store
                      </label>
                      <select
                        className={styles.changeStoreModalFormSelect}
                        name="stores"
                        id="stores"
                        value={selectedStore}
                        onChange={handleStoreChange}>
                        <option value={storeID}>{storeName}</option>

                        <optgroup
                          label="Change store to"
                          className={styles.changeStoreModalFormOptGroup}>
                          {storesData.map((store: Store) => (
                            <option key={store.id} value={store.id}>
                              {store.store_name}
                            </option>
                          ))}
                        </optgroup>
                      </select>
                    </div>

                    <Button
                      type="submit"
                      formControl
                      title={
                        isUpdateOrderRelatedStoreMutationLoading
                          ? "Saving..."
                          : "Save Changes"
                      }
                    />
                  </form>
                )}
              </Modal>
            )} */}
          </div>
        )}
      </div>
    </main>
  );
};

export default UserOrders;
