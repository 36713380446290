import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AppContext";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";
import Table from "../../../components/table/Table";
import { Oval } from "react-loader-spinner";

import { exportData } from "../../../handlers/exportData";
import { toast } from "../../../components/toast/toast";
import { getContactForms } from "../../../handlers/contactFormsHandlers";
import ContactFormInfoModal from "./ContactFormInfo/ContactFormInfoModal";

const ContactForms = () => {
  const { user } = useContext(AuthContext);
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isContactFormInfoModalOpen, setIsContactFormInfoModalOpen] =
    useState(false);
  const [currentInfo, setCurrentInfo] = useState<
    IContactFormsData | undefined
  >();

  const { data: contactForms, isLoading } = useQuery([`contact-forms`], () =>
    getContactForms({
      token: cookies["sofresh-admin-token"],
    })
  );

  const handleOpenContactFormInfoModal = ({
    data,
  }: {
    data: IContactFormsData;
  }) => {
    setCurrentInfo(data);
    setIsContactFormInfoModalOpen(true);
  };

  const handleCloseContactFormInfoModal = () => {
    setCurrentInfo(undefined);
    setIsContactFormInfoModalOpen(false);
  };

  const handleExportData = async () => {
    try {
      setIsDownloading(true);
      const responseData = await exportData({
        token: cookies["sofresh-admin-token"],
        type: "contact-form",
      });

      if (responseData.status === "downloaded") {
        toast.success("Data successfully downloaded");
      }
    } catch (error) {
      toast.error("Failed to download! Try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  // TABLE DATA  //
  const columns = [
    {
      name: "Date Created",
      selector: (data: IContactFormsData) => {
        const date = new Date(data.created_at);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      },
      sortable: true,
    },
    {
      name: `Name`,
      selector: (data: IContactFormsData) => data.name,
      sortable: true,
    },
    {
      name: `Email Address`,
      selector: (data: IContactFormsData) => data.email,
      sortable: true,
    },
    {
      name: `Phone Number`,
      selector: (data: IContactFormsData) => data.phone_no,
      sortable: true,
    },
    {
      name: `Subject`,
      selector: (data: IContactFormsData) => data.subject,
      sortable: true,
    },
    {
      name: "Status",
      selector: (data: IContactFormsData) =>
        data.is_active === false ? (
          <span className="badge bg-success">Resolved</span>
        ) : (
          <span className="badge bg-danger">Unresolved</span>
        ),
      sortable: true,
    },
    {
      name: "Action",
      omit: user?.role === "subadmin",
      selector: (data: IContactFormsData) => (
        <div className="flex align-items-center">
          <button
            onClick={() =>
              handleOpenContactFormInfoModal({
                data: data,
              })
            }
            style={{
              background: "transparent",
              outline: "transparent",
              border: "transparent",
            }}
          >
            <i className="bi bi-eye-fill me-2"></i>
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <main className="content">
      <div className="container-fluid p-0">
        {(user?.role === "admin" || user?.role === "subadmin") && (
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-between">
              <h4 className="h4 py-3">Contact Forms</h4>

              <button
                onClick={handleExportData}
                className="btn btn-sm btn-success h-fit"
              >
                {isDownloading ? (
                  <Oval
                    color="white"
                    wrapperClass="mx-4"
                    height={10}
                    width={10}
                  />
                ) : (
                  "Export Data"
                )}
              </button>
            </div>

            <div className="col-12 d-flex">
              <div className="card flex-fill">
                <div className="card">
                  <div className="card-body">
                    {isLoading ? (
                      <div className="my-8 w-full h-full flex align-items-center justify-center">
                        <Oval
                          height="40"
                          width="40"
                          color="#495057"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <Table data={contactForms.data} columns={columns} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isContactFormInfoModalOpen && (
              <ContactFormInfoModal
                isOpen={isContactFormInfoModalOpen}
                onClose={handleCloseContactFormInfoModal}
                currentInfo={currentInfo}
              />
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default ContactForms;
