import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../../context/AppContext";
import { useCookies } from "react-cookie";
import { getPlanTypes } from "../../../handlers/productHandler";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../components/table/Table";
import { Oval } from "react-loader-spinner";
import { formatToNigerianNaira } from "../../../utils/helper";
import { MdAddShoppingCart } from "react-icons/md";
import AddPlanTypeToAdminsStoreModal from "./AddPlanTypeToAdminsStoreModal";
import { exportData } from "../../../handlers/exportData";
import { toast } from "../../../components/toast/toast";

interface IData {
  name: string;
  description: string;
  duration: any;
  is_active: boolean;
  is_available: boolean;
  plan: any;
  slug: string;
  image: Array<string>;
}

const PlanTypes = () => {
  const { user } = useContext(AuthContext);
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const navigate = useNavigate();

  const [slug, setSlug] = useState<string>("");
  const [planTypeName, setPlanTypeName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isAddPlanTypeToStoreModalOpen, setIsAddPlanTypeToStoreModalOpen] =
    useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { data, isLoading } = useQuery("plan-types", () =>
    getPlanTypes(cookies["sofresh-admin-token"])
  );

  const handleOpenAddPlanTypeToSubAdminsStoreModal = ({
    slug,
    planTypeName,
    isActive,
  }: {
    slug: string;
    planTypeName: string;
    isActive: boolean;
  }) => {
    setSlug(slug);
    setPlanTypeName(planTypeName);
    setIsActive(isActive);
    setIsAddPlanTypeToStoreModalOpen(true);
  };
  const handleCloseAddPlanTypeToSubAdminsStoreModal = () => {
    setSlug("");
    setIsAddPlanTypeToStoreModalOpen(false);
  };

  const handleExportData = async () => {
    try {
      setIsDownloading(true);
      const responseData = await exportData({
        token: cookies["sofresh-admin-token"],
        type: "plan_type",
      });

      if (responseData.status === "downloaded") {
        toast.success("Data successfully downloaded");
      }
    } catch (error) {
      toast.error("Failed to download! Try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  const columns = [
    {
      name: "Plan Type Name",
      width: "150px",
      selector: (row: IData) => row.name,
      sortable: true,
    },
    {
      name: "Plan Name",
      selector: (row: IData) => row.plan?.name,
      sortable: true,
    },
    {
      name: "Price Range",
      width: "220px",
      selector: (row: IData) =>
        row.duration.length > 1
          ? `${formatToNigerianNaira(
              row.duration[0].price
            )} - ${formatToNigerianNaira(row.duration[1].price)}`
          : `${formatToNigerianNaira(row.duration[0].price)}`,
      sortable: true,
    },
    {
      name: "Durations",
      selector: (row: IData) =>
        row.duration.length > 1
          ? `${row.duration[0].days} - ${row.duration[1].days} Day(s)`
          : `${row.duration[0].days} Day(s)`,
      sortable: true,
    },
    {
      name: "Image",
      width: "100px",
      selector: (row: IData) => (
        <img
          src={row?.image[0]}
          alt={row.name}
          style={{ width: "40px", aspectRatio: "1/1" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: IData) => (
        <span
          className={
            row.is_active
              ? "badge bg-success outline-0 border-0"
              : "badge bg-danger outline-0 border-0"
          }
        >
          {row.is_active ? <span>Active</span> : <span>Inactive</span>}
        </span>
      ),
      sortable: true,
    },
    {
      name: user?.role === "subadmin" && "In Store",
      selector: (row: IData) =>
        user?.role === "subadmin" && (
          <span
            className={
              row.is_available
                ? "badge bg-success outline-0 border-0"
                : "badge bg-danger outline-0 border-0"
            }
          >
            {row.is_available ? <span>Yes</span> : <span>No</span>}
          </span>
        ),
      sortable: true,
      omit: user?.role === "admin",
    },
    {
      name: "Actions",
      selector: (row: IData) => (
        <div className="flex align-items-center">
          {user?.role === "admin" ? (
            <button
              onClick={() => navigate(`/plan-types/${row.slug}/edit`)}
              style={{
                background: "transparent",
                outline: "transparent",
                border: "transparent",
              }}
            >
              <i className="bi bi-pencil-square me-2"></i>
            </button>
          ) : (
            <button
              onClick={() =>
                handleOpenAddPlanTypeToSubAdminsStoreModal({
                  slug: row.slug,
                  planTypeName: row.name,
                  isActive: row.is_available,
                })
              }
              className={`bg-transparent outline-0 border-0
                ${row.is_available ? "text-success" : "text-danger"}`}
              style={{ fontSize: "1.2rem" }}
            >
              <MdAddShoppingCart />
            </button>
          )}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="h3">Plan Types</h1>
            {user?.role === "admin" && (
              <div className="d-flex gap-3">
                <button
                  onClick={handleExportData}
                  className="btn btn-sm btn-success"
                >
                  {isDownloading ? (
                    <Oval
                      color="white"
                      wrapperClass="mx-4"
                      height={10}
                      width={10}
                    />
                  ) : (
                    "Export Data"
                  )}
                </button>

                <Link to={"/plan-types/add"} className="btn btn-sm btn-warning">
                  Create Plan Types <i className="bi bi-bag-plus-fill"></i>
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex">
            <div className="card flex-fill">
              {isLoading ? (
                <div className="my-5 w--100 h--100 d-flex  align-items-center justify-content-center">
                  <Oval height="40" width="40" color="#495057" visible={true} />
                </div>
              ) : (
                <div className="card">
                  <div className="card-body">
                    <Table data={data} columns={columns} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isAddPlanTypeToStoreModalOpen && (
        <AddPlanTypeToAdminsStoreModal
          isOpen={isAddPlanTypeToStoreModalOpen}
          onClose={handleCloseAddPlanTypeToSubAdminsStoreModal}
          slug={slug}
          planTypeName={planTypeName}
          is_active={isActive}
        />
      )}
    </main>
  );
};

export default PlanTypes;
