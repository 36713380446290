import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { AuthContext } from "../context/AppContext";
import feather from "feather-icons";
import Applogo from "../assets/logo.svg";
// import { BsShopWindow } from "react-icons/bs";
// import { IoFastFoodOutline, IoSettingsOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
// import { PiUsersBold, PiMapPinLineBold, PiUserList } from "react-icons/pi";
import { PiMapPinLineBold, PiUserList } from "react-icons/pi";
import { HiOutlineShoppingBag, HiOutlineShoppingCart } from "react-icons/hi";
import { HiOutlineMegaphone } from "react-icons/hi2";
import { LuBackpack } from "react-icons/lu";
import { FiSliders } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { TbHeadset } from "react-icons/tb";

interface IDashboardLayout {
  children: any;
}

const DashboardLayout: FC<IDashboardLayout> = ({ children }) => {
  // GET YEAR
  const date = new Date();
  let year = date.getFullYear();

  const { handleAuthState, user } = useContext(AuthContext);
  // const [cookies] = useCookies(['sofresh-admin-token', 'sofresh-admin-user'])
  const [cookies] = useCookies(["sofresh-admin-token", "sofresh-admin-user"]);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const adminToken = cookies["sofresh-admin-token"];
  const adminUser = cookies["sofresh-admin-user"];
  const navigate = useNavigate();
  const location = useLocation();

  const ROLE = cookies["sofresh-admin-user"]?.role;
  const STORE = cookies["sofresh-admin-user"]?.store?.name;

  const handleLogout = useCallback(() => {
    document.cookie = "";
    handleAuthState(null);
    navigate("/");
  }, [handleAuthState, navigate]);

  const checkAuthState = () => {
    if (!adminToken) {
      handleLogout();
    } else if (adminToken && adminUser) {
      handleAuthState({
        user: adminUser,
        token: adminToken,
      });
    }
  };
  useEffect(() => {
    checkAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminToken, adminUser]);

  // useEffect(() => {
  //   if (!adminToken) {
  //     handleLogout()
  //     return
  //   }

  //   handleAuthState({
  //     user: adminUser,
  //     token: adminToken,
  //   })
  //   // }, [cookies, handleAuthState, handleLogout])
  // }, [adminToken, adminUser])

  // useEffect(() => {
  //   if (!cookies['sofresh-admin-token']) {
  //     handleLogout()
  //   }
  //   if (cookies['sofresh-admin-token'] && cookies['sofresh-admin-user']) {
  //     handleAuthState({
  //       user: cookies['sofresh-admin-user'],
  //       token: cookies['sofresh-admin-token'],
  //     })
  //   }
  // }, [cookies['sofresh-admin-token'], cookies['sofresh-admin-user']])
  // // }, [cookies, handleAuthState, handleLogout])

  useEffect(() => {
    feather.replace();
  });

  return (
    <div className="wrapper">
      <nav id="sidebar" className="sidebar js-sidebar">
        <div
          className="sticky-top"
          style={{ background: "inherit", zIndex: "9999999999" }}
        >
          <Link className="sidebar-brand" to="/dashboard">
            <img src={Applogo} alt="" className="img-fluid" />
          </Link>
          <li className="sidebar-header">
            {ROLE === "admin"
              ? "Back Office - Admin"
              : `${STORE ? STORE : "Store"} - Sub Admin`}
          </li>
        </div>

        <div className="sidebar-content js-simplebar">
          <ul className="sidebar-nav navbar-nav-scroll">
            <li
              className={`sidebar-item ${
                location.pathname === "/dashboard" && "active"
              }`}
            >
              <Link className="sidebar-link" to="/dashboard">
                <FiSliders
                  className="align-middle"
                  style={{ fontSize: "1.4rem" }}
                />
                <span className="align-middle">Dashboard</span>
              </Link>
            </li>

            <li
              className={`sidebar-item ${
                location.pathname === "/orders" && "active"
              }`}
            >
              <Link className="sidebar-link" to={"/orders"}>
                <HiOutlineShoppingCart
                  className="align-middle"
                  style={{ fontSize: "1.4rem" }}
                />
                <span className="align-middle">Orders</span>
              </Link>
            </li>

            {user?.role === "admin" && (
              <>
                <li
                  className={`sidebar-item ${
                    location.pathname.includes("/user-accounts") && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/user-accounts">
                    <PiUserList
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Accounts</span>
                  </Link>
                </li>

                {/* <li
                  className={`sidebar-item ${
                    location.pathname.includes("/sub-admins/") && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/sub-admins">
                    <PiUsersBold
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Sub Admins</span>
                  </Link>
                </li> */}

                {/* <li
                  className={`sidebar-item ${
                    location.pathname === "/stores" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to={"/stores"}>
                    <BsShopWindow
                      className="align-middle pe-1"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Stores</span>
                  </Link>
                </li> */}

                <li className="sidebar-item">
                  <a
                    className="sidebar-link dropdown-toggle"
                    href="#homePlans"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                  >
                    <LuBackpack
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Plans</span>
                  </a>
                  <ul className="collapse list-unstyled" id="homePlans">
                    <li
                      className={`sidebar-item ml-4 ${
                        location.pathname === "/plans" && "active"
                      }`}
                    >
                      <Link className="sidebar-link" to="/plans">
                        <span className="align-middle ms-1">Plans</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ml-4 ${
                        location.pathname === "/plan-types" && "active"
                      }`}
                    >
                      <Link className="sidebar-link" to="/plan-types">
                        <span className="align-middle ms-1">Plan Types</span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="sidebar-item">
                  <a
                    className="sidebar-link dropdown-toggle"
                    href="#deliveriesNav"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                  >
                    <PiMapPinLineBold
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Deliveries</span>
                  </a>
                  <ul className="collapse list-unstyled" id="deliveriesNav">
                    <li
                      className={`sidebar-item ${
                        location.pathname === "/deliverypoints" && "active"
                      }`}
                    >
                      <Link
                        className="sidebar-link ml-4"
                        to={"/deliverypoints"}
                      >
                        <span className="align-middle ms-1">
                          Delivery Points
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={`sidebar-item ${
                        location.pathname === "/free-deliveries" && "active"
                      }`}
                    >
                      <Link
                        className="sidebar-link ml-4"
                        to={"/free-deliveries"}
                      >
                        <span className="align-middle ms-1">Free Delivery</span>
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </>
            )}

            <li className="sidebar-item">
              <a
                className="sidebar-link dropdown-toggle"
                href="#productsNav"
                data-bs-toggle="collapse"
                aria-expanded="false"
              >
                <HiOutlineShoppingBag
                  className="align-middle"
                  style={{ fontSize: "1.4rem" }}
                />
                <span className="align-middle">Products</span>
              </a>
              <ul className="collapse list-unstyled" id="productsNav">
                <li
                  className={`sidebar-item ${
                    location.pathname === "/products" && "active"
                  }`}
                >
                  <Link className="sidebar-link ml-4" to={"/products"}>
                    <span className="align-middle ms-1">Products</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item ml-4 ${
                    location.pathname === "/products/categories" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/product/categories">
                    <span className="align-middle ms-1">
                      Product Categories
                    </span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li className="sidebar-item">
              <Link className="sidebar-link" to={"/extras"}>
                <IoFastFoodOutline
                  className="align-middle"
                  style={{ fontSize: "1.4rem" }}
                />
                <span className="align-middle">Extras</span>
              </Link>
            </li> */}

            <li className="sidebar-item">
              <a
                className="sidebar-link dropdown-toggle"
                href="#marketingNav"
                data-bs-toggle="collapse"
                aria-expanded="false"
              >
                <HiOutlineMegaphone
                  className="align-middle"
                  style={{ fontSize: "1.4rem" }}
                />
                <span className="align-middle">Marketing</span>
              </a>
              <ul className="collapse list-unstyled" id="marketingNav">
                {/* <li
                  className={`sidebar-item ${
                    location.pathname === "/marketing/discount-ad" && "active"
                  }`}
                >
                  <Link
                    className="sidebar-link ml-4"
                    to={"/marketing/discount-ad"}
                  >
                    <span className="align-middle ms-1">Discount ADs</span>
                  </Link>
                </li> */}
                {/* <li
                  className={`sidebar-item ${
                    location.pathname === "/marketing/reviews" && "active"
                  }`}
                >
                  <Link className="sidebar-link ml-4" to={"/marketing/reviews"}>
                    <span className="align-middle ms-1">Reviews</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item ${
                    location.pathname === "/marketing/newsletter" && "active"
                  }`}
                >
                  <Link
                    className="sidebar-link ml-4"
                    to={"/marketing/newsletter"}
                  >
                    <span className="align-middle ms-1">Newsletter</span>
                  </Link>
                </li> */}
                <li
                  className={`sidebar-item ${
                    location.pathname.includes("/marketing/blogs") && "active"
                  }`}
                >
                  <Link className="sidebar-link ml-4" to={"/marketing/blogs"}>
                    <span className="align-middle ms-1">Blog</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item ${
                    location.pathname.includes("/marketing/blog-categories") &&
                    "active"
                  }`}
                >
                  <Link
                    className="sidebar-link ml-4"
                    to={"/marketing/blog-categories"}
                  >
                    <span className="align-middle ms-1">Blog Categories</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="sidebar-item">
              <a
                className="sidebar-link dropdown-toggle"
                href="#homeSubmenu"
                data-bs-toggle="collapse"
                aria-expanded="false"
              >
                <IoSettingsOutline
                  className="align-middle"
                  style={{ fontSize: "1.4rem" }}
                />
                <span className="align-middle">Settings</span>
              </a>
              <ul className="collapse list-unstyled" id="homeSubmenu">
                {/* <li
                  className={`sidebar-item ml-4 ${
                    location.pathname === "/discounts" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/discounts">
                    <span className="align-middle ms-1">Discounts</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item ml-4 ${
                    location.pathname === "/create-free-deliveries" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/create-free-deliveries">
                    <span className="align-middle ms-1">Free Delivery</span>
                  </Link>
                </li> */}
                <li
                  className={`sidebar-item ml-4 ${
                    location.pathname === "/website-content" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/website-content">
                    <span className="align-middle ms-1">Website Content</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item ml-4 ${
                    location.pathname === "/store-locator" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/store-locator">
                    <span className="align-middle ms-1">Store Locator</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item ml-4 ${
                    location.pathname === "/navigation-buttons" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/navigation-buttons">
                    <span className="align-middle ms-1">
                      Navigation Buttons
                    </span>
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={`sidebar-item ${
                location.pathname === "/contact-forms" && "active"
              }`}
            >
              <Link className="sidebar-link" to={"/contact-forms"}>
                <TbHeadset
                  className="align-middle"
                  style={{ fontSize: "1.4rem" }}
                />
                <span className="align-middle">Contact Forms</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <div className="main">
        <nav className="navbar navbar-expand navbar-light navbar-bg">
          <button
            className="sidebar-toggle js-sidebar-toggle"
            style={{
              outline: "transparent",
              border: "transparent",
              background: "transparent",
            }}
            onClick={() => setToggleSidebar((prev) => !prev)}
          >
            <i className="hamburger align-self-center"></i>
          </button>

          <nav
            id="mobile-sidebar"
            className={`mobile-sidebar ${toggleSidebar && "collapsed"}`}
          >
            <div className="d-flex justify-between">
              <div
                className="sticky-top"
                style={{ background: "inherit", zIndex: "9999999999" }}
              >
                <Link className="sidebar-brand" to="/dashboard">
                  <img src={Applogo} alt="" className="img-fluid" />
                </Link>
                <li className="sidebar-header">
                  {ROLE === "admin"
                    ? "Back Office - Admin"
                    : `${STORE ? STORE : "Store"} - Sub Admin`}
                </li>
              </div>

              <button
                className="sidebar-toggle js-sidebar-toggle"
                style={{
                  outline: "transparent",
                  border: "transparent",
                  background: "transparent",
                  color: "#fff",
                  marginTop: "20px",
                  fontSize: "24px",
                }}
                onClick={() => setToggleSidebar((prev) => !prev)}
              >
                <IoMdClose size={30} />
              </button>
            </div>

            <div className="sidebar-content js-simplebar">
              <ul className="sidebar-nav navbar-nav-scroll">
                <li
                  className={`sidebar-item ${
                    location.pathname === "/dashboard" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/dashboard">
                    <FiSliders
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Dashboard</span>
                  </Link>
                </li>

                <li
                  className={`sidebar-item ${
                    location.pathname === "/orders" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to={"/orders"}>
                    <HiOutlineShoppingCart
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Orders</span>
                  </Link>
                </li>

                {user?.role === "admin" && (
                  <>
                    <li
                      className={`sidebar-item ${
                        location.pathname.includes("/user-accounts") && "active"
                      }`}
                    >
                      <Link className="sidebar-link" to="/user-accounts">
                        <PiUserList
                          className="align-middle"
                          style={{ fontSize: "1.4rem" }}
                        />
                        <span className="align-middle">Accounts</span>
                      </Link>
                    </li>

                    {/* <li
                  className={`sidebar-item ${
                    location.pathname.includes("/sub-admins/") && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/sub-admins">
                    <PiUsersBold
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Sub Admins</span>
                  </Link>
                </li> */}

                    {/* <li
                  className={`sidebar-item ${
                    location.pathname === "/stores" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to={"/stores"}>
                    <BsShopWindow
                      className="align-middle pe-1"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Stores</span>
                  </Link>
                </li> */}

                    <li className="sidebar-item">
                      <a
                        className="sidebar-link dropdown-toggle"
                        href="#homePlans"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                      >
                        <LuBackpack
                          className="align-middle"
                          style={{ fontSize: "1.4rem" }}
                        />
                        <span className="align-middle">Plans</span>
                      </a>
                      <ul className="collapse list-unstyled" id="homePlans">
                        <li
                          className={`sidebar-item ml-4 ${
                            location.pathname === "/plans" && "active"
                          }`}
                        >
                          <Link className="sidebar-link" to="/plans">
                            <span className="align-middle ms-1">Plans</span>
                          </Link>
                        </li>
                        <li
                          className={`sidebar-item ml-4 ${
                            location.pathname === "/plan-types" && "active"
                          }`}
                        >
                          <Link className="sidebar-link" to="/plan-types">
                            <span className="align-middle ms-1">
                              Plan Types
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="sidebar-item">
                      <a
                        className="sidebar-link dropdown-toggle"
                        href="#deliveriesNav"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                      >
                        <PiMapPinLineBold
                          className="align-middle"
                          style={{ fontSize: "1.4rem" }}
                        />
                        <span className="align-middle">Deliveries</span>
                      </a>
                      <ul className="collapse list-unstyled" id="deliveriesNav">
                        <li
                          className={`sidebar-item ${
                            location.pathname === "/deliverypoints" && "active"
                          }`}
                        >
                          <Link
                            className="sidebar-link ml-4"
                            to={"/deliverypoints"}
                          >
                            <span className="align-middle ms-1">
                              Delivery Points
                            </span>
                          </Link>
                        </li>
                        {/* <li
                      className={`sidebar-item ${
                        location.pathname === "/free-deliveries" && "active"
                      }`}
                    >
                      <Link
                        className="sidebar-link ml-4"
                        to={"/free-deliveries"}
                      >
                        <span className="align-middle ms-1">Free Delivery</span>
                      </Link>
                    </li> */}
                      </ul>
                    </li>
                  </>
                )}

                <li className="sidebar-item">
                  <a
                    className="sidebar-link dropdown-toggle"
                    href="#productsNav"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                  >
                    <HiOutlineShoppingBag
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Products</span>
                  </a>
                  <ul className="collapse list-unstyled" id="productsNav">
                    <li
                      className={`sidebar-item ${
                        location.pathname === "/products" && "active"
                      }`}
                    >
                      <Link className="sidebar-link ml-4" to={"/products"}>
                        <span className="align-middle ms-1">Products</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ml-4 ${
                        location.pathname === "/products/categories" && "active"
                      }`}
                    >
                      <Link className="sidebar-link" to="/product/categories">
                        <span className="align-middle ms-1">
                          Product Categories
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li className="sidebar-item">
              <Link className="sidebar-link" to={"/extras"}>
                <IoFastFoodOutline
                  className="align-middle"
                  style={{ fontSize: "1.4rem" }}
                />
                <span className="align-middle">Extras</span>
              </Link>
            </li> */}

                <li className="sidebar-item">
                  <a
                    className="sidebar-link dropdown-toggle"
                    href="#marketingNav"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                  >
                    <HiOutlineMegaphone
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Marketing</span>
                  </a>
                  <ul className="collapse list-unstyled" id="marketingNav">
                    {/* <li
                  className={`sidebar-item ${
                    location.pathname === "/marketing/discount-ad" && "active"
                  }`}
                >
                  <Link
                    className="sidebar-link ml-4"
                    to={"/marketing/discount-ad"}
                  >
                    <span className="align-middle ms-1">Discount ADs</span>
                  </Link>
                </li> */}
                    {/* <li
                  className={`sidebar-item ${
                    location.pathname === "/marketing/reviews" && "active"
                  }`}
                >
                  <Link className="sidebar-link ml-4" to={"/marketing/reviews"}>
                    <span className="align-middle ms-1">Reviews</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item ${
                    location.pathname === "/marketing/newsletter" && "active"
                  }`}
                >
                  <Link
                    className="sidebar-link ml-4"
                    to={"/marketing/newsletter"}
                  >
                    <span className="align-middle ms-1">Newsletter</span>
                  </Link>
                </li> */}
                    <li
                      className={`sidebar-item ${
                        location.pathname.includes("/marketing/blogs") &&
                        "active"
                      }`}
                    >
                      <Link
                        className="sidebar-link ml-4"
                        to={"/marketing/blogs"}
                      >
                        <span className="align-middle ms-1">Blog</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ${
                        location.pathname.includes(
                          "/marketing/blog-categories"
                        ) && "active"
                      }`}
                    >
                      <Link
                        className="sidebar-link ml-4"
                        to={"/marketing/blog-categories"}
                      >
                        <span className="align-middle ms-1">
                          Blog Categories
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="sidebar-item">
                  <a
                    className="sidebar-link dropdown-toggle"
                    href="#homeSubmenu"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                  >
                    <IoSettingsOutline
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Settings</span>
                  </a>
                  <ul className="collapse list-unstyled" id="homeSubmenu">
                    {/* <li
                  className={`sidebar-item ml-4 ${
                    location.pathname === "/discounts" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/discounts">
                    <span className="align-middle ms-1">Discounts</span>
                  </Link>
                </li>
                <li
                  className={`sidebar-item ml-4 ${
                    location.pathname === "/create-free-deliveries" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to="/create-free-deliveries">
                    <span className="align-middle ms-1">Free Delivery</span>
                  </Link>
                </li> */}
                    <li
                      className={`sidebar-item ml-4 ${
                        location.pathname === "/website-content" && "active"
                      }`}
                    >
                      <Link className="sidebar-link" to="/website-content">
                        <span className="align-middle ms-1">
                          Website Content
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ml-4 ${
                        location.pathname === "/store-locator" && "active"
                      }`}
                    >
                      <Link className="sidebar-link" to="/store-locator">
                        <span className="align-middle ms-1">Store Locator</span>
                      </Link>
                    </li>
                    <li
                      className={`sidebar-item ml-4 ${
                        location.pathname === "/navigation-buttons" && "active"
                      }`}
                    >
                      <Link className="sidebar-link" to="/navigation-buttons">
                        <span className="align-middle ms-1">
                          Navigation Buttons
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`sidebar-item ${
                    location.pathname === "/contact-forms" && "active"
                  }`}
                >
                  <Link className="sidebar-link" to={"/contact-forms"}>
                    <TbHeadset
                      className="align-middle"
                      style={{ fontSize: "1.4rem" }}
                    />
                    <span className="align-middle">Contact Forms</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>

          <nav className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align">
              <li className="nav-item dropdown">
                <button
                  className="nav-icon dropdown-toggle"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "none",
                  }}
                  id="alertsDropdown"
                  data-bs-toggle="dropdown"
                >
                  <div className="position-relative">
                    <i className="align-middle" data-feather="bell"></i>
                    <span className="indicator">4</span>
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                  aria-labelledby="alertsDropdown"
                >
                  <div className="dropdown-menu-header">
                    4 New Notifications
                  </div>
                  <div className="dropdown-menu-footer">
                    <button
                      style={{
                        outline: "none",
                        border: "none",
                        background: "none",
                      }}
                      className="text-muted"
                    >
                      Show all notifications
                    </button>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                <button
                  className="nav-icon dropdown-toggle"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "none",
                  }}
                  id="messagesDropdown"
                  data-bs-toggle="dropdown"
                >
                  <div className="position-relative">
                    <i
                      className="align-middle"
                      data-feather="message-square"
                    ></i>
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                  aria-labelledby="messagesDropdown"
                >
                  <div className="dropdown-menu-header">
                    <div className="position-relative">4 New Messages</div>
                  </div>
                  <div className="dropdown-menu-footer">
                    <button
                      style={{
                        outline: "none",
                        border: "none",
                        background: "none",
                      }}
                      className="text-muted"
                    >
                      Show all messages
                    </button>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                <button
                  className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "none",
                  }}
                  data-bs-toggle="dropdown"
                >
                  <i className="align-middle" data-feather="settings"></i>
                </button>

                <button
                  className="nav-link dropdown-toggle d-none d-sm-inline-block"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "none",
                  }}
                  data-bs-toggle="dropdown"
                >
                  <img
                    src={Applogo}
                    className="avatar img-fluid rounded me-1"
                    alt="Logo"
                  />{" "}
                  {user?.role === "admin" ? (
                    <span className="text-dark">Hello, Admin!</span>
                  ) : (
                    <span className="text-dark">Hello, {user?.name}!</span>
                  )}
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <button
                    className="dropdown-item"
                    style={{
                      outline: "none",
                      border: "none",
                      background: "none",
                    }}
                  >
                    <i className="align-middle me-1" data-feather="user"></i>{" "}
                    Profile
                  </button>
                  <div className="dropdown-divider"></div>
                  <button
                    className="dropdown-item"
                    style={{
                      outline: "none",
                      border: "none",
                      background: "none",
                    }}
                  >
                    <i
                      className="align-middle me-1"
                      data-feather="settings"
                    ></i>{" "}
                    Settings & Privacy
                  </button>
                  <div className="dropdown-divider"></div>
                  <button
                    style={{
                      outline: "none",
                      border: "none",
                      background: "none",
                    }}
                    className="dropdown-item"
                    onClick={handleLogout}
                  >
                    Log out
                  </button>
                </div>
              </li>
            </ul>
          </nav>
        </nav>

        <main className="main-navbar">
          {children}
          <footer className="footer">
            <div className="container-fluid">
              <div className="row text-muted">
                <div className="col-12 text-center">
                  <p className="mb-0">
                    <a className="text-muted" href="/" target="_blank">
                      <strong>Sofresh</strong>
                    </a>{" "}
                    - &copy; {year}
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
