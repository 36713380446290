import { FC, useState, useEffect, useRef } from "react";
import Modal from "../../../../components/Modal";
import styles from "./ContactFormInfo.module.css";
import { useMutation, useQueryClient } from "react-query";
import { editContactForm } from "../../../../handlers/contactFormsHandlers";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const ContactFormInfoModal: FC<IContactFormInfoModalProps> = ({
  isOpen,
  onClose,
  currentInfo,
}) => {
  const [data, setData] = useState(currentInfo);
  const [formattedDate, setFormattedDate] = useState("");

  const [cookies] = useCookies(["sofresh-admin-token"]);

  const switchRef = useRef<HTMLInputElement | null>(null);
  const handleCheck = () => {
    switchRef.current?.click();
  };
  const [checkValue, setCheckValue] = useState(false);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daySuffix = (day: number) => {
    if (day % 10 === 1 && day !== 11) return "st";
    if (day % 10 === 2 && day !== 12) return "nd";
    if (day % 10 === 3 && day !== 13) return "rd";
    return "th";
  };

  const queryClient = useQueryClient();

  const { mutate: editContactFormMutation } = useMutation(editContactForm, {
    onSuccess: async (res: any) => {
      toast.success(res.message);
      await queryClient.invalidateQueries(["contact-forms"]);
      if (res.status === true) {
        onClose();
      }
    },
    onError: (error: unknown) => {
      console.error(error);
      toast.error("Failed to update blog category, please try again!");
    },
  });

  function handleCheckValue(checked: boolean) {
    console.log(!checked);
    setCheckValue(checked);
    if (currentInfo) {
      const payload = {
        ...currentInfo,
        is_active: !checked,
      };

      try {
        editContactFormMutation({
          token: cookies["sofresh-admin-token"],
          contactForm: payload,
          id: currentInfo.id as string,
        });
      } catch (error) {
        console.error(error);
        toast.error("Failed to update blog category, please try again!");
      }
    }
  }

  useEffect(() => {
    setData(currentInfo);
    const date = new Date(currentInfo?.created_at ?? "");
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, "0");
    setFormattedDate(`${day}${daySuffix(Number(day))} ${month} ${year}`);

    if (currentInfo?.is_active) {
      setCheckValue(false);
    } else {
      setCheckValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInfo]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={``} subTitle={``}>
      <div className={styles.contactFormInfoContainer}>
        <div className={styles.contactFormGridLayout}>
          <ContactInfoDisplay label="Date Created" value={formattedDate} />
          <ContactInfoDisplay label="Full Name" value={data?.name} />
          <ContactInfoDisplay label="Email Address" value={data?.email} />
          <ContactInfoDisplay label="Phone Number" value={data?.phone_no} />
          <div className={styles.contactFormInfoLabelContainer}>
            <p className={styles.contactFormInfoLabel}>Status</p>

            <p className={styles.contactFormInfoValue}>
              {data?.is_active === false ? (
                <span className="badge bg-success">Resolved</span>
              ) : (
                <span className="badge bg-danger">Unresolved</span>
              )}
            </p>
          </div>
          <ContactInfoDisplay label="Subject" value={data?.subject} />
        </div>
        <ContactInfoDisplay label="Complaints" value={data?.content} />

        <div className={styles.switchContainer}>
          <input
            className={styles.switchInputCheckbox}
            type="checkbox"
            ref={switchRef}
            checked={checkValue ? true : false}
            onChange={(e) => {
              handleCheckValue(e.target.checked);
            }}
          />

          <button
            type="button"
            className={styles.switchInput}
            onClick={handleCheck}
          >
            <div className={styles.switchInputDot}></div>
          </button>

          <p className={styles.switchInputLabel}>
            {checkValue ? "Mark as unresolved" : "Mark as resolved"}
          </p>
        </div>
      </div>
    </Modal>
  );
};
export default ContactFormInfoModal;

function ContactInfoDisplay({
  label,
  value,
}: {
  label: string;
  value?: string;
}) {
  return (
    <div className={styles.contactFormInfoLabelContainer}>
      <p className={styles.contactFormInfoLabel}>{label}</p>
      <p className={styles.contactFormInfoValue}>{value}</p>
    </div>
  );
}
