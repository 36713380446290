// REACT & DEFAULTS
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";

// LIBs & UTILs
import {
  BlogCategoryData,
  CreateBlogCategoryResponse,
  editBlogCategory,
  getBlogCategory,
} from "../../../../../handlers/blogsHandlers";
import Button from "../../../../../components/button/button";
import { toast } from "react-toastify";

// STYLES
import styles from "../BlogCategories.module.css";

const EditBlogCategory = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [blogCategoryData, setBlogCategoryData] = useState<BlogCategoryData>({
    title: "",
    description: "",
    is_active: true,
  });

  const { slug: blogCategorySlug } = useParams();

  // GET BLOG POST
  const { data: fetchedBlogCategoryData } = useQuery(
    ["blog-categories", blogCategorySlug],
    () =>
      getBlogCategory({
        token: cookies["sofresh-admin-token"],
        slug: blogCategorySlug as string,
      })
  );

  // HANDLE INPUT VALUE CHANGE
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setBlogCategoryData((prevData: BlogCategoryData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSetActive = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "true") {
      setBlogCategoryData((prevProduct) => ({
        ...prevProduct,
        is_active: true,
      }));
    } else {
      setBlogCategoryData((prevProduct) => ({
        ...prevProduct,
        is_active: false,
      }));
    }
  };

  
  const {
    mutate: editBlogCategoryMutation,
    isLoading: isCreateBlogCategoryMutationLoading,
  } = useMutation(editBlogCategory, {
    onSuccess: async (res: CreateBlogCategoryResponse) => {
      toast.success(res.message);
      await queryClient.invalidateQueries(["blog-categories"]);
      if (res.status === true) {
        setBlogCategoryData({
          title: "",
          description: "",
          is_active: true,
        });
        navigate("/marketing/blog-categories");
      }
    },
    onError: (error: unknown) => {
      console.error(error);
      toast.error("Failed to update blog category, please try again!");
    },
  });

  // HANDLE FORM SUBMISSION
  const handleEditBlogCategory = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.info('BLOG_CATEGORY_DATA: ', blogCategoryData)
    try {
      editBlogCategoryMutation({
        token: cookies["sofresh-admin-token"],
        blogCategory: blogCategoryData,
        slug: blogCategorySlug as string,
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to update blog category, please try again!");
    }
  };

  useEffect(() => {
    if (fetchedBlogCategoryData) {
      setBlogCategoryData({
        title: fetchedBlogCategoryData.title,
        description: fetchedBlogCategoryData.description,
        is_active: fetchedBlogCategoryData.is_active,
      });
    }
  }, [fetchedBlogCategoryData]);

  return (
    <main className={`container-fluid ${styles.blogPostBackground}`}>
      <section className="px-4 py-4">
        <div className="row px-0">
          <div className="w-100 col-md-12 d-flex  align-items-center justify-content-start py-4">
            <h1 className="h2 m-0">Edit Blog Category</h1>
          </div>
        </div>

        <div className="row px-0">
          <form onSubmit={handleEditBlogCategory}>
            <div className="row d-flex flex-column align-items-start">
              <div className={`form-group ${styles.formGroup}`}>
                <label htmlFor="title">Blog Category Title</label>
                <input
                  name="title"
                  onChange={handleChange}
                  value={blogCategoryData.title}
                  placeholder="Enter Blog Category Title"
                  type="text"
                  className={`form-control py-2 my-2`}
                  required
                />
              </div>

              <div className={`form-group ${styles.formGroup}`}>
                <label htmlFor="description">Blog Description</label>
                <input
                  name="description"
                  onChange={handleChange}
                  value={blogCategoryData.description}
                  placeholder="Enter Blog Category Description"
                  type="text"
                  className={`form-control py-2 my-2`}
                  required
                />
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="is_active">
                  Status
                </label>
                <select
                  className="form-select form-select-lg"
                  name="is_active"
                  placeholder="Blog status"
                  onChange={handleSetActive}
                  // onBlur={handleBlur}
                  value={blogCategoryData.is_active ? "true" : "false"}
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
            </div>

            <div className="my-4">
              <Button
                disabled={isCreateBlogCategoryMutationLoading}
                loading={isCreateBlogCategoryMutationLoading}
                title="Update Blog Category"
                type="submit"
                className={`text-white`}
              />
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default EditBlogCategory;
