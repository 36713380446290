import { AxiosError } from "axios";
import { axiosClient } from "../utils/axiosClient";

type exportDataOptions = {
  token: string;
  type: string;
};

export const exportData = async ({
  token,
  type,
}: exportDataOptions): Promise<any> => {
  try {
    const response = await axiosClient.get(`admin/exports?type=${type}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    // product | plan | plan_type | order | delivery_point;
    const blob = new Blob([response.data], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${type}.csv`;

    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    return { status: "downloaded" };
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    console.error(axiosError);
    throw new Error("An error occurred. Please try again.");
  }
};
