// REACT & DEFAULTS
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import ReactS3Client from "react-aws-s3-typescript";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCookies } from "react-cookie";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// LIBs & UTILs
import { s3Config } from "../../../../../utils/s3Config";
import {
  CreateBlogData,
  CreateBlogPostResponse,
  GetBlogCategoriesResponse,
  editBlogPost,
  getBlogCategories,
  getBlogPost,
} from "../../../../../handlers/blogsHandlers";
import Button from "../../../../../components/button/button";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

// IMAGES & ICONS
import upload from "../../../../../assets/uploadPNG.png";

// STYLES
import styles from "../Blogs.module.css";
import "react-quill/dist/quill.snow.css";

const EditBlogPost = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [images, setImages] = useState<string>("");
  const [isActive, setIsActive] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { slug: blogSlug } = useParams();

  // GET BLOG POST
  const { data: fetchedBlogData } = useQuery(
    ["blog-post", blogSlug],
    () =>
      getBlogPost({
        token: cookies["sofresh-admin-token"],
        slug: blogSlug as string,
      })
  );

  // GET BLOG CATEGORIES
  const { data: blogCategories, isLoading: isBlogCategoriesLoading } = useQuery(
    "blog-categories",
    () => getBlogCategories({ token: cookies["sofresh-admin-token"] })
  );

  // REACT_QUILL RICH_TEXT_EDITOR
  // var toolbarOptions = [
  //   [{ font: [] }],
  //   [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //   ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  //   ['blockquote', 'code-block'],
  //   [{ header: 1 }, { header: 2 }], // custom button values
  //   [{ list: 'ordered' }, { list: 'bullet' }],
  //   [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  //   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  //   [{ direction: 'rtl' }], // text direction
  //   [{ color: ['#70707B'] }, { background: ['#f9faff'] }], // dropdown with defaults from theme
  //   [{ align: [] }],
  //   ['clean'], // remove formatting button
  // ]
  const [richTextValue, setRichTextValue] = useState<string>("");
  const [blogData, setBlogData] = useState<CreateBlogData>({
    title: "",
    blog_category_id: undefined,
    images: [images],
    article: "",
  });

  // Update blogData whenever richTextValue changes
  useEffect(() => {
    setBlogData((prevBlogPostData) => ({
      ...prevBlogPostData,
      article: richTextValue,
    }));
  }, [richTextValue]);

  // HANDLE INPUT VALUE CHANGE
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setBlogData((prevData: CreateBlogData) => ({
      ...prevData,

      [name]: name === "blog_category_id" ? Number(value) : value,
    }));
  };

  // HANDLE ACTIVE VALUE CHANGE
  const handleSetActive = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "true") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  // HANDLE PRODUCT IMAGE UPLOAD
  const handleUploadBtnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (file: File) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "blogs",
    });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (error) {
      console.error(error);
    }
  };

  const handleBlogPostImageUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setSelectedFile(file);
    setUploading(true);

    try {
      const imageUrl = await handleFileUpload(file);
      setImages(String(imageUrl));
      setBlogData((prevBlogPostData: CreateBlogData) => ({
        ...prevBlogPostData,
        images: [imageUrl],
      }));
    } catch (error) {
      console.error(error);
      toast.error("Failed to upload image");
    } finally {
      setUploading(false);
    }
  }; // END OF HANDLE PRODUCT IMAGE UPLOAD

  // CREATE BLOG POST FORM SUBMISSION
  const {
    mutate: editBlogPostMutation,
    isLoading: isCreateBlogPostMutationLoading,
  } = useMutation(editBlogPost, {
    onSuccess: async (res: CreateBlogPostResponse) => {
      toast.success(res.message);
      await queryClient.invalidateQueries(["blogs"]);
      if (res.status === true) {
        setImages("");
        setBlogData({
          title: "",
          blog_category_id: undefined,
          images: [""],
          article: "",
        });

        navigate("/marketing/blogs");
      }
    },
    onError: (error: unknown) => {
      console.error(error);
      // toast.error(e)
      toast.error("Failed to create blog post, please try again!");
    },
  });

  // HANDLE FORM SUBMISSION
  const handleCreateBlogPost = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.info('BLOGDATA: ', blogData)
    try {
      editBlogPostMutation({
        token: cookies["sofresh-admin-token"],
        blogPost: { ...blogData, is_active: isActive },
        slug: blogSlug as string,
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to create blog post, please try again!");
    }
  };

  useEffect(() => {
    if (fetchedBlogData) {
      setImages(fetchedBlogData?.data.images[0]);
      setIsActive(fetchedBlogData?.data.is_active);
      setRichTextValue(fetchedBlogData?.data.article);

      setBlogData({
        title: fetchedBlogData?.data.title,
        blog_category_id: fetchedBlogData?.data.category.id,
        images: fetchedBlogData?.data.images,
        article: fetchedBlogData?.data.article,
      });
    }
  }, [fetchedBlogData]);

  return (
    <main
      className={`blogPostBackgroundcontainer-fluid ${styles.blogPostBackground}`}
    >
      <section className="px-4 py-4">
        <div className="row px-0">
          <div className="w-100 col-md-12 d-flex  align-items-center justify-content-start py-4">
            <h1 className="h2 m-0">Edit Blog Post</h1>
          </div>
        </div>

        {isBlogCategoriesLoading ? (
          <div className="my-5 w-100 d-flex  align-items-center justify-content-center">
            <Oval height="40" width="40" color="#495057" visible={true} />
          </div>
        ) : (
          <div className="row px-0">
            <form onSubmit={handleCreateBlogPost}>
              <div className="row row-cols-md-3">
                <div className="form-group ">
                  <label htmlFor="title">Blog Title</label>
                  <input
                    name="title"
                    onChange={handleChange}
                    value={blogData.title}
                    // value={createBlogData.title}
                    placeholder="Enter Blog Title"
                    type="text"
                    className={`form-control py-2 my-2`}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="title">Blog Category</label>
                  <select
                    className="form-control py-2 my-2"
                    name="blog_category_id"
                    id="title"
                    onChange={handleChange}
                    value={blogData.blog_category_id}
                  >
                    <option value={undefined}>Select a Blog Category</option>
                    {blogCategories?.map(
                      (blogCategory: GetBlogCategoriesResponse) => (
                        <option value={blogCategory.id} key={blogCategory.slug}>
                          {blogCategory.title}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="is_active">
                    Status
                  </label>
                  <select
                    className="form-select form-select-lg"
                    name="is_active"
                    placeholder="Blog status"
                    onChange={handleSetActive}
                    // onBlur={handleBlur}
                    value={isActive ? "true" : "false"}
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </div>
              </div>

              <div className={`py-2 form-group ${styles.imageUploadSection}`}>
                <div
                  className={`py-4 ${styles.imageContainer}`}
                  style={{
                    width: selectedFile ? "250px" : "unset",
                  }}
                >
                  {uploading ? (
                    <Oval wrapperClass="ml-2" height={20} width={20} />
                  ) : (
                    <>
                      <figure>
                        <img
                          src={
                            images
                              ? images
                              : selectedFile
                              ? URL.createObjectURL(selectedFile)
                              : upload
                          }
                          alt="preview"
                          width={selectedFile || images ? "100%" : undefined}
                          height={selectedFile || images ? "100%" : undefined}
                          onClick={handleUploadBtnClick}
                          className={styles.uploadIcon}
                        />

                        {selectedFile && (
                          <figcaption>{selectedFile.name}</figcaption>
                        )}
                      </figure>
                    </>
                  )}
                  <input
                    type="file"
                    id="uploadProductImage"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleBlogPostImageUpload}
                  />
                </div>
                <p>
                  <label htmlFor="uploadProductImage">
                    <span className={`pe-1 ${styles.caption}`}>
                      Click to upload
                    </span>
                  </label>
                  or drag and drop image
                </p>
                <p className={styles.captionText}>
                  PNG, JPEG, WEBP
                  <span className="ps-1">
                    (image should be of landscape orientation 16 x 9)
                  </span>
                </p>
              </div>

              <div>
                <ReactQuill
                  theme="snow"
                  value={richTextValue}
                  onChange={setRichTextValue}
                  // modules={{ toolbar: toolbarOptions }}
                  // formats={['#editor']}
                  // modules={{
                  //   toolbar: {
                  //     toolbarOptions,
                  //   },
                  // }}
                />
              </div>

              <div className="my-4">
                <Button
                  disabled={isCreateBlogPostMutationLoading}
                  loading={isCreateBlogPostMutationLoading}
                  title="Update Blog Post"
                  type="submit"
                  className={`text-white`}
                />
              </div>
            </form>
          </div>
        )}
      </section>
    </main>
  );
};

export default EditBlogPost;
