import { useCookies } from "react-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
// import PrivateRoute from '../routes/PrivateRoute'

import Login from "../pages/Login";
import Dashboard from "../pages/auth/Dashboard";
// import SubAdmins from "../pages/auth/sub-admins/SubAdmins";
// import CreateSubAdmin from "../pages/auth/sub-admins/CreateSubAdmin";
// import EditSubAdmin from "../pages/auth/sub-admins/EditSubAdmin";
// import Stores from "../pages/auth/store/Stores";
// import CreateStore from "../pages/auth/store/Old/CreateStore";
// import EditStore from "../pages/auth/store/Old/EditStore";
import Products from "../pages/auth/products/Products";
import CreateProduct from "../pages/auth/products/CreateProduct";
import UserOrders from "../pages/auth/orders/UserOrders";
import SingleOrderView from "../pages/auth/orders/SingleOrderView";
import EditPlanType from "../pages/auth/meal-plan/EditPlanType";
import CreatePlanType from "../pages/auth/meal-plan/CreatePlanType";
import PlanTypes from "../pages/auth/meal-plan/PlanTypes";
import Plans from "../pages/auth/plans/Plans";
import CreatePlan from "../pages/auth/plans/CreatePlans";
import UpdatePlan from "../pages/auth/plans/UpdatePlan";
import ProductCategories from "../pages/auth/ProductCategories";
import CreateProductCategories from "../pages/auth/products/CreateProductCategories";
import EditProductCategory from "../pages/auth/products/EditProductCategory";
// import Extras from "../pages/auth/extras/Extras";
// import CreateExtras from "../pages/auth/extras/CreateExtras";
// import EditExtras from "../pages/auth/extras/EditExtras";
import DeliveryPoints from "../pages/auth/DeliveryPoints";
// import FreeDeliveries from "../pages/auth/free-deliveries";
// import CreateFreeDeliveries from "../pages/auth/settings/CreateFreeDeliveries";
// import Discounts from "../pages/auth/settings/Discounts";
// import CreateDiscount from "../pages/auth/settings/CreateDiscount";
// import ViewDiscounts from "../pages/auth/settings/ViewDiscount";
// import UpdateDiscounts from "../pages/auth/settings/UpdateDiscount";
// import DiscountADS from "../pages/auth/marketing/DiscountADS";
// import Reviews from "../pages/auth/marketing/Reviews";
// import Newsletter from "../pages/auth/marketing/Newsletter";
import UserAccounts from "../pages/auth/user-accounts";
import UpdateSingleUserAccount from "../pages/auth/user-accounts/update-single-user-account";
import SingleUserAccount from "../pages/auth/user-accounts/single-user-account";
import Blog from "../pages/auth/marketing/Blog";
import EditBlogPost from "../pages/auth/marketing/Blog/EditBlogPost";
import CreateBlogPost from "../pages/auth/marketing/Blog/CreateBlogPost";
import BlogCategories from "../pages/auth/marketing/BlogCategories";
import CreateBlogCategory from "../pages/auth/marketing/BlogCategories/CreateBlogCategory";
import EditBlogCategory from "../pages/auth/marketing/BlogCategories/EditBlogCategory";
import WebsiteContent from "../pages/auth/website-content";
// import SingleDelivery from "../pages/auth/single-delivery";
import StoreLocator from "../pages/auth/store-locator";
import AddLocation from "../pages/auth/add-location";
import EditLocation from "../pages/auth/edit-location";
import NavigationButton from "../pages/auth/navigation-buttons";
import CreateNavigationButton from "../pages/auth/create-button";
import UpdateNavigationButton from "../pages/auth/update-button";
import ContactForms from "../pages/auth/contact-forms/ContactForms";

// import EditProduct from '../pages/auth/products/EditProduct'
// import Discounts from '../pages/auth/settings/Discounts'
// import CreateDiscount from '../pages/auth/settings/CreateDiscount'

const AppRoutes = () => {
  const [cookies] = useCookies(["sofresh-admin-user"]);
  const ROLE = cookies["sofresh-admin-user"]?.role;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          }
        />
        <Route
          path="/user-accounts"
          element={
            <DashboardLayout>
              <UserAccounts />
            </DashboardLayout>
          }
        />
        <Route
          path="/user-accounts/:id"
          element={
            <DashboardLayout>
              <SingleUserAccount />
            </DashboardLayout>
          }
        />
        <Route
          path="/user-accounts/update/:id"
          element={
            <DashboardLayout>
              <UpdateSingleUserAccount />
            </DashboardLayout>
          }
        />
        {/* SUB ADMINS */}
        {/* <Route
          path="/sub-admins"
          element={
            <DashboardLayout>
              <SubAdmins />
            </DashboardLayout>
          }
        />
        <Route
          path="/sub-admins/:id/edit"
          element={
            <DashboardLayout>
              <EditSubAdmin />
            </DashboardLayout>
          }
        />
        <Route
          path="/sub-admins/add"
          element={
            <DashboardLayout>
              <CreateSubAdmin />
            </DashboardLayout>
          }
        />*/}
        {/* STORES */}
        {/*
        <Route
          path="/stores"
          element={
            <DashboardLayout>
              <Stores />
            </DashboardLayout>
          }
        />
        <Route
          path={"/stores/:slug/edit"}
          element={
            <DashboardLayout>
              <EditStore />
            </DashboardLayout>
          }
        />
        <Route
          path="/stores/add"
          element={
            <DashboardLayout>
              <CreateStore />
            </DashboardLayout>
          }
        /> */}
        <Route
          path="/products"
          element={
            <DashboardLayout>
              <Products />
            </DashboardLayout>
          }
        />
        <Route
          path="/products/add"
          element={
            <DashboardLayout>
              <CreateProduct />
            </DashboardLayout>
          }
        />
        <Route
          path="/orders"
          element={
            <DashboardLayout>
              <UserOrders />
            </DashboardLayout>
          }
        />
        <Route
          path="/orders/:reference"
          element={
            <DashboardLayout>
              <SingleOrderView />
            </DashboardLayout>
          }
        />
        <Route
          path="/product/categories"
          element={
            <DashboardLayout>
              <ProductCategories />
            </DashboardLayout>
          }
        />
        <Route
          path="/products/categories/add"
          element={
            <DashboardLayout>
              <CreateProductCategories />
            </DashboardLayout>
          }
        />
        <Route
          path="/plans"
          element={
            <DashboardLayout>
              <Plans />
            </DashboardLayout>
          }
        />
        {ROLE === "admin" && (
          <>
            <Route
              path="/products/categories/:slug/edit"
              element={
                <DashboardLayout>
                  <EditProductCategory />
                </DashboardLayout>
              }
            />
            <Route
              path="/plans/add"
              element={
                <DashboardLayout>
                  <CreatePlan />
                </DashboardLayout>
              }
            />
            <Route path="/plans/:slug/edit" element={<UpdatePlan />} />
            <Route
              path="/plan-types"
              element={
                <DashboardLayout>
                  <PlanTypes />
                </DashboardLayout>
              }
            />
            <Route path="/plan-types/:slug/edit" element={<EditPlanType />} />
            <Route
              path="/plan-types/add"
              element={
                <DashboardLayout>
                  <CreatePlanType />
                </DashboardLayout>
              }
            />

            {/* FREE DELIVERIES */}
            {/* <Route
              path="/free-deliveries"
              element={
                <DashboardLayout>
                  <FreeDeliveries />
                </DashboardLayout>
              }
            />
            <Route
              path="/single-delivery"
              element={
                <DashboardLayout>
                  <SingleDelivery />
                </DashboardLayout>
              }
            />
            <Route
              path="/create-free-deliveries"
              element={
                <DashboardLayout>
                  <CreateFreeDeliveries />
                </DashboardLayout>
              }
            /> */}
          </>
        )}
        <Route
          path="/deliverypoints"
          element={
            <DashboardLayout>
              <DeliveryPoints />
            </DashboardLayout>
          }
        />
        {/* <Route
          path="/discounts"
          element={
            <DashboardLayout>
              <Discounts />
            </DashboardLayout>
          }
        />
        <Route
          path="/discounts/add"
          element={
            <DashboardLayout>
              <CreateDiscount />
            </DashboardLayout>
          }
        />
        <Route
          path="/discounts/:id/view"
          element={
            <DashboardLayout>
              <ViewDiscounts />
            </DashboardLayout>
          }
        />
        <Route
          path="/discounts/:id/edit"
          element={
            <DashboardLayout>
              <UpdateDiscounts />
            </DashboardLayout>
          }
        /> */}

        {/* EXTRAS */}
        {/* <Route
          path={"/extras"}
          element={
            <DashboardLayout>
              <Extras />
            </DashboardLayout>
          }
        />
        <Route
          path={"/extras/add"}
          element={
            <DashboardLayout>
              <CreateExtras />
            </DashboardLayout>
          }
        />
        <Route
          path={"/extras/:slug/edit"}
          element={
            <DashboardLayout>
              <EditExtras />
            </DashboardLayout>
          }
        /> */}
        <Route
          path={"/website-content"}
          element={
            <DashboardLayout>
              <WebsiteContent />
            </DashboardLayout>
          }
        />
        <Route
          path={"/store-locator"}
          element={
            <DashboardLayout>
              <StoreLocator />
            </DashboardLayout>
          }
        />
        <Route
          path={"/navigation-buttons"}
          element={
            <DashboardLayout>
              <NavigationButton />
            </DashboardLayout>
          }
        />
        <Route
          path={"/navigation-buttons/create"}
          element={
            <DashboardLayout>
              <CreateNavigationButton />
            </DashboardLayout>
          }
        />
        <Route
          path={"/navigation-buttons/:id/edit"}
          element={
            <DashboardLayout>
              <UpdateNavigationButton />
            </DashboardLayout>
          }
        />
        <Route
          path={"/add-location"}
          element={
            <DashboardLayout>
              <AddLocation />
            </DashboardLayout>
          }
        />
        <Route
          path={"/store-locator/:slug/edit-location"}
          element={
            <DashboardLayout>
              <EditLocation />
            </DashboardLayout>
          }
        />
        {/* MARKETING ROUTES */}
        {/* DISCOUNT ADS */}
        {/* <Route
          path="/marketing/discount-ad"
          element={
            <DashboardLayout>
              <DiscountADS />
            </DashboardLayout>
          }
        /> */}
        {/* <Route
          path="/marketing/reviews"
          element={
            <DashboardLayout>
              <Reviews />
            </DashboardLayout>
          }
        />
        <Route
          path="/marketing/newsletter"
          element={
            <DashboardLayout>
              <Newsletter />
            </DashboardLayout>
          }
        /> */}
        <Route
          path="/marketing/blogs"
          element={
            <DashboardLayout>
              <Blog />
            </DashboardLayout>
          }
        />
        <Route
          path="/marketing/blogs/create"
          element={
            <DashboardLayout>
              <CreateBlogPost />
            </DashboardLayout>
          }
        />
        <Route
          path="/marketing/blogs/:slug/edit"
          element={
            <DashboardLayout>
              <EditBlogPost />
            </DashboardLayout>
          }
        />
        <Route
          path="/marketing/blog-categories"
          element={
            <DashboardLayout>
              <BlogCategories />
            </DashboardLayout>
          }
        />
        <Route
          path="/marketing/blog-categories/create"
          element={
            <DashboardLayout>
              <CreateBlogCategory />
            </DashboardLayout>
          }
        />

        <Route
          path="/marketing/blog-categories/:slug/edit"
          element={
            <DashboardLayout>
              <EditBlogCategory />
            </DashboardLayout>
          }
        />

        <Route
          path="/contact-forms"
          element={
            <DashboardLayout>
              <ContactForms />
            </DashboardLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
