// REACT & DEFAULTS
import { ChangeEvent, FormEvent, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// LIBs & UTILs
import {
  BlogCategoryData,
  CreateBlogCategoryResponse,
  createBlogCategory,
} from "../../../../../handlers/blogsHandlers";
import Button from "../../../../../components/button/button";
import { toast } from "react-toastify";

// STYLES
import styles from "../BlogCategories.module.css";

const CreateBlogCategory = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [blogCategoryData, setBlogCategoryData] = useState<BlogCategoryData>({
    title: "",
    description: "",
    is_active: true,
  });

  // HANDLE INPUT VALUE CHANGE
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setBlogCategoryData((prevData: BlogCategoryData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // CREATE BLOG POST FORM SUBMISSION
  const {
    mutate: createBlogCategoryMutation,
    isLoading: isCreateBlogCategoryMutationLoading,
  } = useMutation(createBlogCategory, {
    onSuccess: async (res: CreateBlogCategoryResponse) => {
      toast.success(res.message);
      await queryClient.invalidateQueries(["blog-categories"]);
      if (res.status === true) {
        setBlogCategoryData({
          title: "",
          description: "",
          is_active: true,
        });
        navigate("/marketing/blog-categories");
      }
    },
    onError: (error: unknown) => {
      console.error(error);
      toast.error("Failed to create blog category, please try again!");
    },
  });

  // HANDLE FORM SUBMISSION
  const handleCreateBlogCategory = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.info('BLOG_CATEGORY_DATA: ', blogCategoryData)
    try {
      createBlogCategoryMutation({
        token: cookies["sofresh-admin-token"],
        blogCategory: blogCategoryData,
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to create blog post, please try again!");
    }
  };

  return (
    <main className={`container-fluid ${styles.blogPostBackground}`}>
      <section className="px-4 py-4">
        <div className="row px-0">
          <div className="w-100 col-md-12 d-flex  align-items-center justify-content-start py-4">
            <h1 className="h2 m-0">Create Blog Category</h1>
          </div>
        </div>

        <div className="row px-0">
          <form onSubmit={handleCreateBlogCategory}>
            <div className="row d-flex flex-column align-items-start">
              <div className={`form-group ${styles.formGroup}`}>
                <label htmlFor="title">Blog Category Title</label>
                <input
                  name="title"
                  onChange={handleChange}
                  value={blogCategoryData.title}
                  placeholder="Enter Blog Category Title"
                  type="text"
                  className={`form-control py-2 my-2`}
                  required
                />
              </div>

              <div className={`form-group ${styles.formGroup}`}>
                <label htmlFor="description">Blog Description</label>
                <input
                  name="description"
                  onChange={handleChange}
                  value={blogCategoryData.description}
                  placeholder="Enter Blog Category Description"
                  type="text"
                  className={`form-control py-2 my-2`}
                  required
                />
              </div>
            </div>

            <div className="my-4">
              <Button
                disabled={isCreateBlogCategoryMutationLoading}
                loading={isCreateBlogCategoryMutationLoading}
                title="Create Blog Category"
                type="submit"
                className={`text-white`}
              />
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default CreateBlogCategory;
