import { axiosClient } from "../utils/axiosClient";

type GetContactFormsOptions = {
  token: string;
  page?: number;
};

type EditContactFormOptions = {
  token: string;
  contactForm: IContactFormsData;
  id: string;
};

export const getContactForms = async ({ token }: GetContactFormsOptions) => {
  try {
    const response = await axiosClient.get(
      `/admin/settings/complaints?page_size=5000`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const editContactForm = async ({
  token,
  contactForm,
  id,
}: EditContactFormOptions) => {
  try {
    const response = await axiosClient.put(
      `/admin/settings/complaints/${id}`,
      contactForm,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("An error occurred. Please try again.");
  }
};
